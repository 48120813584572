import React from 'react';

const HTML = ( { children, tag, ...p } ) => {

  const Tag = tag || 'div';

    return(
      <Tag
      dangerouslySetInnerHTML={ { __html: children } }
      { ...p }
      />
    );
}
export default HTML;