import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Link from 'molecules/Link';
import './Button.scss';

/**
 * Button.
 */
const propTypes = {
  color:    PropTypes.string,
  htmlType: PropTypes.string,
  icon:     PropTypes.node,
  link:     PropTypes.object, // Link props.
  size:     PropTypes.size,
  type:     PropTypes.string,
  onClick:  PropTypes.func,
}
const defaultProps = {
  color: 'blue',
  type:  'default',
}
const Button = p => {

  if( ! p.link && ! p.onClick && p.htmlType !== 'submit' ) {
    throw new Error( 'Button needs some sort of functionality.' );
  }

  /* ------ = Output. = --------------------------------------------------------------------- */

  const className = classnames(
    'Btn',
    `is-${p.color}`,
    `is-${p.type}`,
    `is-${p.size}`,
    p.className
  );

  if( !! p.link ) {

    return(

      <Link
      className={ className }
      to={ p.link.to }
      target={ p.link.target }
      >

        { p.icon &&

          <span className="Btn__icon">
            { p.icon }
          </span>

        }

        <span className="Btn__txt">
          { p.children }
        </span>

      </Link>

    )
  }

  else {

    return(

      <button
      className={ className }
      onClick={ p.onClick }
      >

        { p.icon &&

          <span className="Btn__icon">
            { p.icon }
          </span>

        }
        <span className="Btn__txt">
          { p.children }
        </span>

      </button>

    );
  }

};
Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
